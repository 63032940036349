import { Box, SvgIcon } from '@mui/material'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import footerlogo from "../../assets/images/itwlogo.png";
import iatalogo from "../../assets/images/IATA.png";
import "./footer.css"
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AddIcCallTwoToneIcon from '@mui/icons-material/AddIcCallTwoTone';
import WhatsappTwoToneIcon from '@mui/icons-material/WhatsApp';

function Footer() {
   
    return (
        <div className="footer" id="footer">
            <Container fluid>
                <Row>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3} className="zerogap footerlogowrp">
                        <Box className="footerimg">
                            <Box className="footeraddress">Social Links</Box>
                           
                            <Box className="socialicons">
                                <a href="https://www.facebook.com/ITWUserXperience" target="_blank"><SvgIcon><FacebookIcon /></SvgIcon></a>
                                <a href="https://www.instagram.com/itw_uxp/" target="_blank"><SvgIcon><InstagramIcon /></SvgIcon></a>
                                <a href="https://www.linkedin.com/company/itw-uxp/about/" target="_blank"><SvgIcon><LinkedInIcon /></SvgIcon></a>
                            </Box>
                            
                        </Box>
                       
                       
                   </Col>
                    <Col xs={12} sm={12} md={9} lg={9} xl={9} className="zerogap">
                        <Box className="listlinkitemsbox">
                            <Box className="footeraddress">
                                Email ID
                                <p><a 
                                // onClick={ sendEmail}
                                href='mailto:hi@itwuxp.com?subject=Enquiry&body=Hi%20goes%20here'
                                >hi@itwuxp.com</a></p>
                            </Box>
                            <Box className="footeraddress">
                                Contact Us
                                <p className='footerPhoneNo'>+91 80958 88008</p>
                                <p className='footerPhoneNo'>+91 95914 61984</p>
                            </Box>
                            <Box className="footeraddress">
                                Address
                                <p>No. 6, AUM, Millers Tank Bund Rd, Vasanth Nagar, Bengaluru, Karnataka 560052</p>
                            </Box>
                            
                            <Box className="linklistul">
                                <ul>
                                    <li><a href="faq" to="faq" target='_blank'>FAQ</a></li>
                                    <li><a href="our-services" to="our-services" target='_blank'>Our Services</a></li>
                                    <li><a href="privacy-policy" to="privacy-policy" target='_blank'>Privacy policy</a></li>
                                    <li><a href="terms-and-condition" to="terms-and-condition" target='_blank'>Terms & Conditions</a></li>
                                    {/* <li><a to="#">link el\ement</a></li>
                                    <li><a to="#">link element</a></li>
                                    <li><a to="#">link element</a></li>
                                    <li><a to="#">link element</a></li>
                                    <li><a to="#">link element</a></li> */}
                                </ul>
                            </Box>
                            
                        </Box>
                    </Col>
                    
                </Row>
            </Container>
        </div>
    )
}

export default Footer
